import {useRef} from 'react';
import {NextSeo} from 'next-seo';
import {
    GlobalNavigationMainContent,
    GlobalNavigationSkipToContentLink,
    GlobalNavigationHeader,
    GlobalNavigationFooter,
} from '@hy-vee/global-navigation';
import {getNearestStoreFromCookie} from '@hy-vee/user-location';
import {CartProvider} from '@hy-vee/react-web-and-mobile-ui-components';
import {useRouter} from 'next/router';

import {redirectToPath} from '../../utils/redirect';
import {useAislesOnlineCart} from '../../hooks/use-aisles-online-cart';
import {
    getLocationText,
    handleDataChange,
} from '../../utils/global-nav-helpers';
import {useModalContext} from '../modals/modal-provider';
import {
    GEOLOCATION,
    HYFISH_WEB_FOOTER,
    HYFISH_WEB_HEADER,
} from '../../enums/feature-toggle-names';
import {useFeatureToggle} from '../../feature-toggle';
import {login} from '../../utils/callback-helpers';

import styles from './Page.module.css';

const Page = ({
    customer,
    customStyles,
    isAuthenticated,
    store,
    Component,
    componentProps,
    title,
    description,
    url,
}) => {
    const router = useRouter();
    const {storeSelector} = useModalContext();
    const startOfContentRef = useRef(null);

    const onStoreChange = () => {
        if (!isAuthenticated) {
            login(router.asPath);
        }

        storeSelector.show();
    };

    const {featureEnabled} = useFeatureToggle();
    const isNewFooterEnabled = featureEnabled(HYFISH_WEB_FOOTER);
    const isNewHeaderEnabled = featureEnabled(HYFISH_WEB_HEADER);
    const isGeolocationEnabled = featureEnabled(GEOLOCATION);
    const unauthenticatedStore = isGeolocationEnabled ? getNearestStoreFromCookie() : undefined;

    const seo = {
        additionalMetaTags: [
            {
                className: 'swiftype',
                content: description,
                'data-type': 'text',
                name: 'body',
            },
        ],
        description,
        openGraph: {
            description,
            'site_name': 'Hy-Vee',
            title,
            url,
        },
        title,
    };

    const {data: cart} = useAislesOnlineCart(
        customer,
        Boolean(customer?.__typename),
    );
    const fulfillmentConfig = {
        fulfillmentExpiration: cart?.fulfillmentExpiration,
        fulfillmentTime: {
            windowEnd: cart?.fulfillmentWindowEnd,
            windowStart: cart?.fulfillmentWindowStart,
        },
        fulfillmentType: cart?.fulfillmentType,
        locationText: getLocationText(cart, store?.name ? store : unauthenticatedStore),
        onStoreChange,
    };

    return (
        <div>
            <CartProvider cart={cart}>
                <GlobalNavigationSkipToContentLink
                    startOfContentRef={startOfContentRef}
                />
                <NextSeo {...seo} />
                {!componentProps?.hideHeader ? (
                    <GlobalNavigationHeader
                        featureFlagHeaderRed={isNewHeaderEnabled}
                        onDataChange={handleDataChange}
                        onRouteChange={redirectToPath}
                        storeConfig={fulfillmentConfig}
                    />
                ) : null}
                <GlobalNavigationMainContent startOfContentRef={startOfContentRef}>
                    <div className={customStyles ? '' : styles.page}>
                        <Component
                            {...{
                                customer,
                                ...componentProps,
                            }}
                        />
                    </div>
                </GlobalNavigationMainContent>
                <GlobalNavigationFooter featureFlagFooterRed={isNewFooterEnabled} />
            </CartProvider>
        </div>
    );
};

export default Page;
